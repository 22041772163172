import { server } from './util'

const site = process.env.REACT_APP_SITE_URL || 'http://localhost:12345'

const downloadCsv = () => {
  return `${server}/api/download/csv`
}

export default {
  downloadCsv,
  site,
}
