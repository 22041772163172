import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Card,
  Divider,
  Spinner,
  SpinnerSize,
  Icon,
  Colors,
} from '@blueprintjs/core'
import * as api from '../api'
import useTitle from '../utils/title'
import paths from '../utils/paths'
import idUtils from '../utils/id'
import folioUtils from '../utils/folio'
import { WorkCard } from './works'
import { ManuscriptCard, CodicoCard, TextualUnitCard } from './manuscripts'
import {
  Title,
  Crumbline,
  BibLink,
  CatalogueLink,
  ManuscriptLink,
  MediumInfo,
  MiniInfo,
  IdTag,
  Noner,
  Notes,
  BoxTag,
  SortSelect,
} from '../components'

const bibCrumbs = [
  { href: paths.index(), text: 'Reading the Holy Land' },
  { href: paths.bibliographies(), text: 'Bibliography' },
]

const catalogueCrumbs = [
  { href: paths.index(), text: 'Reading the Holy Land' },
  { href: paths.catalogues(), text: 'Catalogues' },
]

const isNumber = num => typeof num === 'number' && !isNaN(num)

export const Bibs = () => {
  const [bibs, setBibs] = useState(null)
  const [refCounts, setRefCounts] = useState(null)
  const [sort, setSort] = useState('Alphabetically')

  useTitle('Bibliography')

  useEffect(() => {
    // filter out catalogues as they're on a separate page and treated differently
    api.bibliographies({ query: { is_catalogue: 0 }}).then(data => setBibs(data))
    api.bibRef().then(data => {
      setRefCounts(data.reduce((acc, curr) => {
        if (!acc.hasOwnProperty(curr.bibliography)) {
          acc[curr.bibliography] = 0
        }
        acc[curr.bibliography] += 1
        return acc
      }, {}))
    })
  }, [])

  useEffect(() => {
    if (refCounts) {
      if (sort === 'Alphabetically') {
        setBibs(prev => {
          if (!Array.isArray(prev)) {
            return prev
          }
          const next = [...prev]
          prev.sort((a, b) => a.name.localeCompare(b.name))
          return next
        })
      } else if (sort === 'References') {
        setBibs(prev => {
          if (!Array.isArray(prev)) {
            return prev
          }
          const next = [...prev]
          prev.sort((a, b) => {
            const aNum = isNumber(refCounts[a.id]) ? refCounts[a.id] : 0
            const bNum = isNumber(refCounts[b.id]) ? refCounts[b.id] : 0
            return bNum - aNum
          })
          return next
        })
      }
    }
  }, [refCounts, sort])

  let display = (
    <Spinner size={SpinnerSize.SMALL} />
  )

  if (refCounts && Array.isArray(bibs) && bibs.length > 0) {
    display = bibs.map(b => {
      const refs = refCounts[b.id] || 0
      return (
        <Card
          key={b.id}
          style={{
            marginBottom: '1.5em',
            backgroundColor: 'transparent',
            border: `1px solid ${Colors.BLACK}`,
            padding: '20px 0 0 0',
            boxShadow: 'none',
          }}
        >
          <div style={{paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px'}}>
            <BibLink bib={b} />
          </div>
          <div
            style={{
              padding: '10px 20px',
              borderTop: `1px solid ${Colors.BLACK}`,
            }}
          >
            <div>Referenced {refs} time{refs === 1 ? '' : 's'}</div>
          </div>
        </Card>
      )
    })
  }

  const onSortChange = e => {
    setSort(e.target.value)
  }

  return (
    <div>
      <Crumbline items={bibCrumbs} />
      <Title>Bibliography</Title>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2.5em', marginBottom: '1em'}}>
        <div>{`${bibs ? bibs.length : 0} items`}</div>
        <SortSelect
          value={sort}
          options={['Alphabetically', 'References']}
          onChange={onSortChange}
        />
      </div>
      <div>
        {display}
      </div>
    </div>
  )
}

const WorkDateAuthorities = ({ works }) => {
  if (!Array.isArray(works)) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const wks = works.map(w => {
    return (
      <div key={w.id} style={{marginBottom: '3em'}}>
        <WorkCard work={w} />
        <MiniInfo
          title="Reference notes"
          info={<Notes notes={w.date_authority.notes} />}
          style={{marginLeft: 0, marginTop: '.75em'}}
        />
      </div>
    )
  })

  return (
    <div>
      {wks.length > 0 ? wks : <Noner />}
    </div>
  )
}

// used for both work studies and work editions
// expects data to be an array with values like { work, bibref }
const WorkRefs = ({ data }) => {
  if (!Array.isArray(data)) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const editions = data.map(d => {
    return (
      <div key={d.bibref.id} style={{marginBottom: '3em'}}>
        <WorkCard work={d.work} />
        <MiniInfo title="Reference notes" info={<Notes notes={d.bibref.notes} />}  style={{marginLeft: 0, marginTop: '.75em'}}/>
      </div>
    )
  })

  return (
    <div>
      {editions.length > 0 ? editions : <Noner />}
    </div>
  )
}

// expects data to be an array with values like { textualUnit, bibref, codicos }
// where codicos are teh codicological units corresponding to textualUnit
const TextualUnitRefs = ({ data }) => {
  if (!Array.isArray(data)) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const tus = data.map(d => {
    return (
      <div key={d.bibref.id} style={{marginBottom: '3em'}}>
        <TextualUnitCard textualUnit={d.textualUnit} codicologicalUnits={d.codicos} showReferences={false} />
        <div style={{display: 'flex', marginTop: '.75em'}}>
          <MiniInfo
            title="Reference notes"
            info={<Notes notes={d.bibref.notes} />}
            style={{marginLeft: 0}}
          />
          <Divider />
          <MiniInfo
            title="Manuscript"
            info={<ManuscriptLink manuscript={d.textualUnit.manuscript} />}
          />
        </div>
      </div>
    )
  })

  return (
    <div>
      {tus.length > 0 ? tus : <Noner />}
    </div>
  )
}

// expects data to be an array with values like { codico, bibref }
// where codicos are the codicological units corresponding to textualUnit
const CodicoRefs = ({ data }) => {
  if (!Array.isArray(data)) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const codicos = data.map(d => {
    return (
      <div key={d.bibref.id} style={{marginBottom: '3em'}}>
        <CodicoCard codico={d.codico} />
        <div style={{display: 'flex', marginTop: '.75em'}}>
          <MiniInfo
            title="Reference notes"
            info={<Notes notes={d.bibref.notes} />}
            style={{marginLeft: 0}}
          />
          <Divider />
          <MiniInfo
            title="Manuscript"
            info={<ManuscriptLink manuscript={d.codico.manuscript} />}
          />
        </div>
      </div>
    )
  })

  return (
    <div>
      {codicos.length > 0 ? codicos : <Noner />}
    </div>
  )
}

// expects data to be an array with values like { bibref, ms }
// codicoMap is a map keyed on manuscript id where the value is a list of codico units related to that manuscript
// where ms is the manuscript
// dateRange is expected to be expanded on the codico
const MsCatalogueRefs = ({ data, codicoMap, workMap, textualUnitMap }) => {
  if (!Array.isArray(data)) {
    return <Spinner size={SpinnerSize.SMALL} />
  }
  const cats = data.map(d => {
    return (
      <div key={d.bibref.id} style={{marginBottom: '3em'}}>
        <ManuscriptCard
          key={d.ms.id}
          manuscript={d.ms}
          codicos={codicoMap[d.ms.id]}
          textualUnits={textualUnitMap[d.ms.id]}
          allWorks={workMap}
        />
        <div style={{display: 'flex', marginTop: '.75em'}}>
          <MiniInfo
            title="Reference notes"
            info={<Notes notes={d.bibref.notes} />}
            style={{marginLeft: 0}}
          />
        </div>
      </div>
    )
  })
  return (
    <div>
      {cats.length > 0 ? cats : <Noner />}
    </div>
  )

}

// used to display an individual bibliography for both normal bibs and catalogues
// set isCatalogue to true for proper rendering of page
export const Bib = ({ isCatalogue=false }) => {
  const { bsig } = useParams()
  const [bib, setBib] = useState(null)
  const [bibRefs, setBibrefs] = useState(null)
  const [allWorks, setAllWorks] = useState(null)
  const [allCodicos, setAllCodicos] = useState(null)
  const [allTextUnits, setAllTextUnits] = useState(null)
  const [allManuscripts, setAllManuscripts] = useState(null)
  const [workMap, setWorkMap] = useState(null)
  const [codicoMap, setCodicoMap] = useState(null)
  const [textualUnitMap, setTextualUnitMap] = useState(null)

  const [workDateAuthorities, setWorkDateAuthorities] = useState(null)
  const [workEditions, setWorkEditions] = useState(null)
  const [workStudies, setWorkStudies] = useState(null)
  const [tuReferences, setTuReferences] = useState(null)
  const [codicoDateAuthorities, setCodicoDateAuthorities] = useState(null)
  const [msCatalogues, setMsCatalogues] = useState(null)

  useTitle(bsig)

  useEffect(() => {
    if (idUtils.isId(bsig)) {
      api.bibliography(bsig).then(b => setBib(b))
    } else {
      api.bibliographies({ query: { identifier: bsig } }).then(bbs => {
        if (!Array.isArray(bbs) || bbs.length !== 1) {
          // TODO proper 404s
          return
        }

        const bib = bbs[0]
        // TODO make this a single query when we're able to expand things properly on general queries (not only id queries)
        api.bibliography(bib.id).then(b => setBib(b))
      })
    }
  }, [bsig])

  useEffect(() => {
    if (bib) {
      api.bibRef({ query: { bibliography: bib.id } })
        .then(brs => {
          setBibrefs(brs.reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {}))
        })
    }
  }, [bib])

  useEffect(() => {
    if (bibRefs && Array.isArray(allWorks) && allWorks.length > 0) {
      setWorkDateAuthorities(allWorks.filter(w => w.date_authority && w.date_authority.id in bibRefs))
      setWorkEditions(allWorks.flatMap(w => {
        if (!w.edition) {
          return []
        }
        return w.edition.map(e => {
          if (e.id in bibRefs) {
            return { work: w, bibref: e }
          }
        })
      }).filter(Boolean))
      setWorkStudies(allWorks.flatMap(w => {
        if (!w.study) {
          return []
        }
        return w.study.map(s => {
          if (s.id in bibRefs) {
            return { work: w, bibref: s }
          }
        })
      }).filter(Boolean))
    }
  }, [allWorks, bibRefs])

  useEffect(() => {
    if (allCodicos && bibRefs && Array.isArray(allTextUnits) && allTextUnits.length > 0) {
      setTuReferences(allTextUnits.flatMap(tu => {
        if (!tu.reference) {
          return []
        }
        return tu.reference.map(r => {
          if (r.id in bibRefs) {
            const codicos = folioUtils.getContainingRanges({
              ranges: allCodicos.filter(c => c.manuscript.id === tu.manuscript.id),
              folioStart: tu.folioStart,
              folioEnd: tu.folioEnd,
              startExtractor: cu => cu.folioStart,
              endExtractor: cu => cu.folioEnd,
            })
            return { textualUnit: tu, bibref: r, codicos }
          }
        })
      }).filter(Boolean).sort((a, b) => a.textualUnit.manuscript.signature.localeCompare(b.textualUnit.manuscript.signature)))
    }
  }, [allTextUnits, allCodicos, bibRefs])

  useEffect(() => {
    if (bibRefs && Array.isArray(allCodicos) && allCodicos.length > 0) {
      setCodicoDateAuthorities(allCodicos.flatMap(cu => {
        if (!cu.date_authority) {
          return []
        }
        return cu.date_authority.map(dr => {
          if (dr.id in bibRefs) {
            return { codico: cu, bibref: dr }
          }
        })
      }).filter(Boolean).sort((a, b) => a.codico.manuscript.signature.localeCompare(b.codico.manuscript.signature)))
    }
  }, [allCodicos, bibRefs])

  useEffect(() => {
    const allLoaded = bib && textualUnitMap && codicoMap && workMap && bibRefs
    if (allLoaded && Array.isArray(allManuscripts) && allManuscripts.length > 0) {
      setMsCatalogues(allManuscripts.flatMap(ms => {
        // check this bib is in any of the catalogue field of manscripts in map
        // check if any bibrefs are in catalogue
        if (Array.isArray(ms.catalogue)) {
          return ms.catalogue.map(cr => {
            if (cr in bibRefs) {
              return { ms, bibref: bibRefs[cr] }
            }
          })
        }
        return false
      }).filter(Boolean))
    }

  }, [allManuscripts, textualUnitMap, codicoMap, workMap, bibRefs, bib])

  useEffect(() => {
    // expecting edition and study to be expanded here, but we only need to specify one of them because of a backend limitation
    api.works({ query: { _ex: 'edition' } }, { sort: false })
      .then(data => {
        setAllWorks(data)

        // map work id to work. used for rendering manuscript cards
        const wm = data.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {})
        setWorkMap(wm)
      })

    api.textualUnits({ query: { _ex: 'reference' } })
      .then(data => {
        setAllTextUnits(data)

        // map manuscript id to array of tus. used for rendering manuscript cards
        const tum = data.reduce((acc, curr) => {
          if (!acc[curr.manuscript.id]) {
            acc[curr.manuscript.id] = []
          }
          acc[curr.manuscript.id].push(curr)
          return acc
        }, {})
        setTextualUnitMap(tum)
      })

    api.codicologicalUnits({ query: { _ex: 'date_authority' } })
      .then(data => {
        setAllCodicos(data)

        // map manuscript id to array of codicos. used for rendering manuscript cards
        const cum = data.reduce((acc, curr) => {
          if (!acc[curr.manuscript.id]) {
            acc[curr.manuscript.id] = []
          }
          acc[curr.manuscript.id].push(curr)
          return acc
        }, {})
        setCodicoMap(cum)
      })

    api.manuscripts({}, { sort: false })
      .then(data => setAllManuscripts(data))
  }, [])

  let middleCrumb = { href: paths.bibliographies(), text: 'Bibliography' }
  if (isCatalogue) {
    middleCrumb = { href: paths.catalogues(), text: 'Catalogues' }
  }

  const crumbs = [
    { href: paths.index(), text: 'Reading the Holy Land' },
    middleCrumb,
    { text: bsig },
  ]
  
  if (!bib) {
    return <Spinner size={SpinnerSize.SMALL} />
  }

  const hasWorkDateAuthorities = Array.isArray(workDateAuthorities) && workDateAuthorities.length > 0
  const hasWorkEditions = Array.isArray(workEditions) && workEditions.length > 0
  const hasWorkStudies = Array.isArray(workStudies) && workStudies.length > 0
  const hasTuReferences = Array.isArray(tuReferences) && tuReferences.length > 0
  const hasCodicoDateAuthorities = Array.isArray(codicoDateAuthorities) && codicoDateAuthorities.length > 0
  const hasMsCatalogues = Array.isArray(msCatalogues) && msCatalogues.length > 0

  const hasReferences = [
    hasWorkDateAuthorities,
    hasWorkEditions,
    hasWorkStudies,
    hasTuReferences,
    hasCodicoDateAuthorities,
    hasMsCatalogues,
  ].reduce((acc, curr) => acc || curr, false)

  // we're loading if at least one of these items are null
  const isLoading = [
    workDateAuthorities,
    workEditions,
    workStudies,
    tuReferences,
    codicoDateAuthorities,
    msCatalogues,
  ].reduce((acc, curr) => acc || (curr === null), false)

  return (
    <div>
      <Crumbline items={crumbs} />
      <Title>{bib.name}</Title>
      <div style={{display: 'flex', marginBottom: '1.5em'}}>
        <MiniInfo
          title="Identifier"
          info={<IdTag id={bib.identifier} />}
          style={{marginLeft: 0}}
        />
        <Divider />
        <MiniInfo
          title="Link"
          info={bib.link ? <a target="_blank" href={bib.link} style={{color: Colors.BLACK}}><span style={{display: 'flex', alignItems: 'center'}}><span style={{marginRight: '.2em'}}>View link</span> <Icon icon="arrow-top-right" size={14} /> </span></a> : <Noner none="No link" />}
        />
      </div>
      { hasWorkDateAuthorities && <MediumInfo title="Appears in 'Works' as 'Date Authority'" info={<WorkDateAuthorities works={workDateAuthorities} />}/> }
      { hasWorkEditions && <MediumInfo title="Appears in 'Works' as 'Edition'" info={<WorkRefs data={workEditions} />}/> }
      { hasWorkStudies && <MediumInfo title="Appears in 'Work' as 'Study'" info={<WorkRefs data={workStudies} />}/> }
      { hasTuReferences && <MediumInfo title="Appears in 'Textual Units' as 'Reference to Manuscript Identification'" info={<TextualUnitRefs data={tuReferences} />}/> }
      { hasCodicoDateAuthorities && <MediumInfo title="Appears in 'Codicological Units' as 'Date Authority'" info={<CodicoRefs data={codicoDateAuthorities} />} /> }
      { hasMsCatalogues &&
          <MediumInfo
            title="Appears in 'Manuscripts' as 'Catalogue'"
            style={{maxWidth: '1024px'}}
            info={
              <MsCatalogueRefs
                data={msCatalogues}
                codicoMap={codicoMap}
                workMap={workMap}
                textualUnitMap={textualUnitMap}
              />
            }
          />
      }
      { (!hasReferences && !isLoading) && <center><Noner none="No references"></Noner></center>}
      { isLoading && <center><Spinner size={SpinnerSize.SMALL} /></center>}
    </div>
  )
}

export const Catalogues = () => {
  const [catalogues, setCatalogues] = useState(null)
  const [refCounts, setRefCounts] = useState(null)
  const [sort, setSort] = useState('Alphabetically')

  useTitle('Catalogues')

  useEffect(() => {
    // filter out non-catalogues as they're on a separate page and treated differently
    api.bibliographies({ query: { is_catalogue: 1 }}).then(data => setCatalogues(data))
    api.bibRef().then(data => {
      setRefCounts(data.reduce((acc, curr) => {
        if (!acc.hasOwnProperty(curr.bibliography)) {
          acc[curr.bibliography] = 0
        }
        acc[curr.bibliography] += 1
        return acc
      }, {}))
    })
  }, [])

  useEffect(() => {
    if (refCounts) {
      if (sort === 'Alphabetically') {
        setCatalogues(prev => {
          if (!Array.isArray(prev)) {
            return prev
          }
          const next = [...prev]
          prev.sort((a, b) => a.name.localeCompare(b.name))
          return next
        })
      } else if (sort === 'References') {
        setCatalogues(prev => {
          if (!Array.isArray(prev)) {
            return prev
          }
          const next = [...prev]
          prev.sort((a, b) => {
            const aNum = isNumber(refCounts[a.id]) ? refCounts[a.id] : 0
            const bNum = isNumber(refCounts[b.id]) ? refCounts[b.id] : 0
            return bNum - aNum
          })
          return next
        })
      }
    }
  }, [refCounts, sort])

  let display = (
    <Spinner size={SpinnerSize.SMALL} />
  )


  if (refCounts && Array.isArray(catalogues) && catalogues.length > 0) {
    display = catalogues.map(c => {
      const refs = refCounts[c.id] || 0
      return (
        <Card
          key={c.id}
          style={{
            marginBottom: '1.5em',
            backgroundColor: 'transparent',
            border: `1px solid ${Colors.BLACK}`,
            padding: '20px 0 0 0',
            boxShadow: 'none',
          }}
        >
          <div style={{paddingBottom: '20px', paddingLeft: '20px', paddingRight: '20px'}}>
            <CatalogueLink catalogue={c} />
          </div>
          <div
            style={{
              padding: '10px 20px',
              borderTop: `1px solid ${Colors.BLACK}`,
            }}
          >
            <div>Referenced {refs} time{refs === 1 ? '' : 's'}</div>
          </div>
        </Card>
      )
    })
  }

  const onSortChange = e => {
    setSort(e.target.value)
  }

  return (
    <div>
      <Crumbline items={catalogueCrumbs} />
      <Title>Catalogues</Title>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '2.5em', marginBottom: '1em'}}>
        <div>{`${catalogues ? catalogues.length : 0} catalogues`}</div>
        <SortSelect
          value={sort}
          options={['Alphabetically', 'References']}
          onChange={onSortChange}
        />
      </div>
      <div>
        {display}
      </div>
    </div>
  )
}

