import Linkify from 'linkify-react'
import { useContext, useState, useEffect } from 'react'
import { IdentifierContext } from '../contexts/identifier'
import idUtils from '../utils/id'
import dateUtils from '../utils/date'
import { Link } from 'react-router-dom'
import {
  Breadcrumbs,
  Breadcrumb,
  AnchorButton,
  Button,
  Classes,
  Card,
  Intent,
  Tag,
  Colors,
  Icon,
  Menu,
  MenuItem,
  HTMLSelect,
} from '@blueprintjs/core'
import * as api from '../api'
import paths from '../utils/paths'
import urls from '../api/urls'
import { plur } from '../utils/string'
import { Tooltip2, Popover2 }from '@blueprintjs/popover2'
import * as popover from '@blueprintjs/popover2'
import './styles.css'

export const Title = ({ children, style }) => {
  return (
    <h2 style={{fontFamily: 'Domine', fontSize: '28px', ...style}}>{children}</h2>
  )
}

const ID = ({ id }) => {
  return <Tag minimal>{idUtils.truncate(id)}</Tag>
}

export const Trunc = ({ text }) => {
  return (
    <div
      style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
    >
      {text}
    </div>
  )
}

// given an array of all ids, searches str for ids and returns an an array with elements
// { index, match, id: '' }
// search is case-insensitive
const findIds = (allIds, str) => {
  const loweredIds = allIds.map(id => id.toLowerCase())
  const res = []
  const re = /\b(\w+)\b/g // break on words
  let match;
  while ((match = re.exec(str)) !== null) {
    const idx = loweredIds.findIndex(id => id === match[0].toLowerCase())
    if (idx !== -1) {
      res.push({
        id: allIds[idx],
        match: match[0],
        index: match.index,
      })
    }
  }
  return res
}

// given string to link and positions, returns an array of elements with ids linked
// if no ids found, returns an array with one element which is the inputted string
const linkIds = (str, positions, { keyPrefix=0 }={}) => {
  const res = []
  let currPos = 0 // current position in str
  const sortedPositions = positions.toSorted((a, b) => a.index - b.index)
  for (let i = 0; i < sortedPositions.length; i++) {
    const pos = sortedPositions[i]
    const len = pos.match.length
    res.push(str.substring(currPos, pos.index)) // add in preceding string
    res.push(
      <BoxTag key={`id-tag-${keyPrefix}-${i}`} link={paths.search(pos.id)}>{pos.id}</BoxTag>
    )
    currPos = pos.index + len
  }
  res.push(str.substring(currPos)) // add in rest of str after searches are exhausted
  return res.filter(Boolean) // filter out empty strings
}


export const Notes = ({ notes, none="No notes", linkOptions, style }) => {
  const { identifiers } = useContext(IdentifierContext)
  const [linkedNotes, setLinkedNotes] = useState(notes)
  useEffect(() => {
    if (notes && Array.isArray(identifiers) && identifiers.length > 0) {
      const positions = findIds(identifiers, notes)
      if (positions.length > 0) {
        const els = linkIds(notes, positions)
        setLinkedNotes(els)
      }
    }
  }, [identifiers, notes])

  if (!notes) {
    return <Noner none={none} />
  }
  return (
    <div style={style}>
      <Linkify options={linkOptions}>
        {linkedNotes}
      </Linkify>
    </div>
  )
}

export const SortSelect = ({ value, options, onChange }) => {
  return (
    <HTMLSelect
      value={value}
      options={options}
      onChange={onChange}
      iconProps={{color: Colors.BLACK}}
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        border: `1px solid ${Colors.BLACK}`,
      }}
    />
  )
}

// displays a date range by either using start/end or dateRange (prefers dateRange over start/end)
// if dateRange is present, it can be either an id, or an expanded dateRange
// if dateRangeMap is passed, expects it to a be map of daterange id to daterange
// ("-" is a hyphen character)
export const DateRange = ({ start, end, dateRange, dateRangeMap }) => {
  const [label, setLabel] = useState(null)
  const [helper, setHelper] = useState(null)

  useEffect(() => {
    if (start === null && end === null && dateRange === null) {
      // the date is not loaded yet
      return
    }
    // first check dates using the date range map if passed
    if (dateRange) {
      if (idUtils.isId(dateRange.id)) { // dateRange has already been expanded
        setLabel(dateRange.name)
        setHelper(`${String(dateRange.start)}–${String(dateRange.end)}`)
        return
      } else if (dateRangeMap) {
        const dr = dateRangeMap[dateRange]
        if (dr) {
          setLabel(dr.name)
          setHelper(`${String(dr.start)}–${String(dr.end)}`)
          return
        }
      }
    } else {
      if (start === end) {
        if (start === 0) {
          setLabel(<Noner none="Unknown">{null}</Noner>)
        } else {
          setLabel(String(start))
        }
        return
      }

      if (dateRangeMap) {
        // generate a map using the start/end. use it to identify the date range if available
        const rangeMap = Object.values(dateRangeMap).reduce((acc, curr) => {
          acc[`${curr.start}-${curr.end}`] = curr
          return acc
        }, {})
        const range = rangeMap[`${start}-${end}`]
        if (range) {
          setLabel(range.name)
          setHelper(`${String(range.start)}–${String(range.end)}`)
          return
        } else {
          // if start or end is falsy, we display an "Unknown" for that part of the range
          let startLabel = <Noner none="Unknown">{start}</Noner>
          let endLabel = <Noner none="Unknown">{end}</Noner>
          setLabel(<span>{startLabel}–{endLabel}</span>)
          return
        }
      }
    }

    // if dates cannot be determined above, load date ranges from the server
    async function load() {
      let nameValue, startValue, endValue;
      if (dateRange) {
        let dr;
        if (!dateRangeMap) {
          dr = await api.dateRange(dateRange)
        } else {
          dr = dateRangeMap[dateRange]
        }
        if (dr) {
          nameValue = dr.name
          startValue = dr.start
          endValue = dr.end
        }
      } else {
        let range;
        if (dateRangeMap) {
          range = dateRangeMap[Object.keys(dateRangeMap).find(drk => dateRangeMap[drk].start === start && dateRangeMap[drk].end === end)]
        } else {
          const ranges = await api.exactDateRanges({ start, end })
          const range = ranges.reduce((acc, curr) => {
            if (!acc || curr.name.length < acc.name.length) {
              return curr
            }
            return acc
          }, null)
        }

        if (range) {
          nameValue = range.name
          startValue = range.start
          endValue = range.end
        } else {
          startValue = start
          endValue = end
        }
      }

      if (nameValue) {
        setLabel(nameValue)
        setHelper(`${String(startValue)}–${String(endValue)}`)
      } else {
        // if start or end is falsy, we display an "Unknown" for that part of the range
        let startLabel = <Noner none="Unknown">{startValue}</Noner>
        let endLabel = <Noner none="Unknown">{endValue}</Noner>
        setLabel(<span>{startLabel}–{endLabel}</span>)
      }
    }

    load()
  }, [start, end, dateRange])

  let display = (
    <span>Loading...</span>
  )

  if (label) {
    display = <span>{label}</span>
  }

  if (helper) {
    display = (
      <Tooltip2 className={popover.Classes.TOOLTIP2_INDICATOR} content={helper}>
        {display}
      </Tooltip2>
    )
  }

  return display
}

// expects start and end to be of type Folio
export const FolioRange = ({ start, end, none='Unknown folios' }) => {
  if (!(start && end) || (start.isEmpty() && end.isEmpty())) {
    return <Noner none={none} />
  }
  // start or end could be empty. if so, they are uknown at this point
  const startText = start.isEmpty() ? '?' : String(start)
  const endText = end.isEmpty() ? '?' : String(end)
  return <span>{startText}–{endText}</span>
}

export const BoxTag = ({ children, style, link, linkProps={}, href, ...props }) => {
  let tag = (
    <span
      className={link ? 'hoverable' : null}
      style={{
        boxSizing: 'border-box',
        padding: '2px 6px',
        border: `1px solid ${Colors.BLACK}`,
        borderRadius: '2px',
        color: Colors.BLACK,
        fontSize: '12px',
        minHeight: '20px',
        minWidth: '20px',
        maxWidth: '100%',
        display: 'inline-flex',
        lineHeight: '16px',
        alignItems: 'center',
        ...style,
      }}
      {...props}
    >
      {children}
    </span>
  )

  if (href) {
    tag = <a href={href} {...linkProps}>{tag}</a>
  } else if (link) {
    tag = <Link to={link} {...linkProps}>{tag}</Link>
  }

  return tag
}

// IdTag has now become a passthrough for BoxTag
export const IdTag = ({ id, ...props }) => {
  return (
    <BoxTag {...props}>{id}</BoxTag>
  )
}

export const Noner = ({ none="None", children }) => {
  if (children) {
    return children
  }
  return(
    <span className={Classes.TEXT_DISABLED}><i>{none}</i></span>
  )
}

const breadcrumbRenderer = ({ text, ...restProps }) => {
  let display = text
  if (idUtils.isId(text)) {
    display = <ID id={text} />
  }
  return (
    <Breadcrumb icon="slash" {...restProps}>{display}</Breadcrumb>
  )
}

export const Crumbs = ({ items }) => {
  return (
    <Breadcrumbs icon="slash" items={items} breadcrumbRenderer={breadcrumbRenderer} />
  )
}

const MiniHead = ({ children, style, ...props }) => {
  return (
    <span
      className={Classes.TEXT_MUTED}
      style={{
        fontSize: '12px',
        ...style,
      }}
      {...props}
    >
      {children}
    </span>
  )
}

export const DataLabel = ({ children, style={}, ...props }) => {
  return (
    <div style={{textTransform: 'uppercase', ...style}} {...props}>
      <span style={{fontSize: '10px', letterSpacing: '.07em', lineHeight: 0}}>{children}</span>
    </div>
  )
}

export const MicroInfo = ({ title, titletip, none="None", info, style={}, headerStyle={}, headerElems, ...props }) => {
  let titleElem = (
    <DataLabel>
      {title}{titletip ? '•' : ''}
    </DataLabel>
  )
  if (titletip) {
    titleElem = (
      <div>
        <Popover2
          popoverClassName={Classes.DARK}
          content={<div style={{padding: '.5em'}}>{titletip}</div>}
          placement="top"
          interactionKind="hover"
          minimal
        >
          <div style={{...headerStyle}}>
            {titleElem} {headerElems}
          </div>
        </Popover2>
      </div>
    )
  }
  return (
    <div style={style} {...props}>
      <div style={{...headerStyle}}>{titleElem} {headerElems}</div>
      <Noner none={none}>{info}</Noner>
    </div>
  )
}

export const MiniInfo = ({ title, none="None", info, style={}, moreInfo, mark }) => {
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.5em',
    marginLeft: '1.5em',
    ...style,
  }
  const data = <Noner none={none}>{info}</Noner>
  let display = (<span>{data} {mark}</span>)

  if (moreInfo) {
    display = (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <span style={{marginRight: '.70em'}}>{data} {mark}</span>
        <InfoTip
          content={moreInfo}
          contentStyle={{padding: '1em', maxWidth: '400px'}}
        />
      </div>
    )
  }

  return (
    <div style={styles}>
      <MiniHead style={{marginBottom: '.25em'}}>{title}</MiniHead>
      {display}
    </div>
  )
}

export const MediumInfo = ({ title, info, style, tooltip=null }) => {
  return (
    <div style={{margin: '3.5em 0', maxWidth: '700px', ...style}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h3 style={{marginRight: '.5em'}}>{title}</h3>
        {tooltip && <InfoTip style={{fontWeight: 'normal'}} content={tooltip} />}
      </div>
      <div>
        <Noner>{info}</Noner>
      </div>
    </div>
  )
}

export const BibLink = ({ bib }) => {
  if (!bib) {
    return <Noner none="No bibliography"></Noner>
  }
  return (
    <CardTitleLink to={paths.bibliographies(bib.identifier)}>{bib.name}</CardTitleLink>
  )
}

export const CatalogueLink = ({ catalogue }) => {
  if (!catalogue) {
    return <Noner none="No catalogue"></Noner>
  }
  return (
    <CardTitleLink to={paths.catalogues(catalogue.identifier)}>{catalogue.name}</CardTitleLink>
  )
}

// setting name will override author name
export const AuthorLink = ({ author, name, style, ...props }) => {
  if (!author) {
    return <Noner none="No author"></Noner>
  }
  return (
    <Link
      to={paths.authors(author.identifier)}
      style={{fontWeight: 'bold', textDecoration: 'none', color: Colors.BLACK, ...style}}
      {...props}
    >
      {name ? name : author.name}
    </Link>
  )
}

export const ManuscriptLink = ({ manuscript }) => {
  return (
    <Link to={paths.manuscripts(manuscript.identifier)}>{manuscript.signature}</Link>
  )
}

export const PopInfo = ({
  title,
  children,
  cardStyle,
  placement="bottom-start",
  render=null,
  disabled=false,
}) => {
  let buttonElem = (
    <Button disabled={disabled} minimal small intent={Intent.PRIMARY}>
      {title}
    </Button>
  )
  if (render) {
   buttonElem = render({ title })
  }
  return (
    <Popover2
      content={
        <Card className={Classes.ELEVATION_1} style={{maxWidth: '400px', ...cardStyle }}>
          <Noner>{children}</Noner>
        </Card>
      }
      minimal
      placement={placement}
      disabled={disabled}
    >
      {buttonElem}
    </Popover2>
  )
}

export const HoverCardPill =  ({ children, hoverable=false, onClick, highlight=false }) => {
  const classNames = [hoverable && 'hoverable', highlight && 'highlighted-content'].filter(Boolean).join(' ')
  return (
    <div
      className={classNames}
      style={{padding: '10px 20px', borderRight: `1px solid ${Colors.BLACK}`, cursor: hoverable ? 'pointer' : null}}
      onClick={hoverable ? onClick : null}
    >
      {children}
    </div>
  )
}

export const HoverableScanLink = ({ children, scanLinks, style }) => {
  let scanDisplay = <div style={style}><Noner none="No scan">{null}</Noner></div>
  if (Array.isArray(scanLinks) && scanLinks.length > 0) {
    if (scanLinks.length > 1) {
      const items = scanLinks.map(sl => (
        <MenuItem key={sl} text={sl} href={sl} target="_blank" />
      ))
      const menu = <Menu>{items}</Menu>
      scanDisplay = (
        <Popover2 className="hoverable" content={menu} placement="bottom-end" minimal>
          <div style={style}>Scans</div>
        </Popover2>
      )
    } else {
      scanDisplay = (
        <a style={style} className="hoverable" href={scanLinks[0]} target="_blank">
          Scan
        </a>
      )
    }
  }
  return scanDisplay
}

// displays a single scan as a link or a dropdown if multiple scans
export const ScanButton = ({ scanLinks }) => {
  let scanDisplay = <Noner none="No scan">{null}</Noner>
  if (Array.isArray(scanLinks) && scanLinks.length > 0) {
    if (scanLinks.length > 1) {
      const items = scanLinks.map(sl => (
        <MenuItem key={sl} text={sl} href={sl} target="_blank" />
      ))
      const menu = <Menu>{items}</Menu>
      scanDisplay = (
        <Popover2 content={menu} placement="bottom-end">
          <Link style={{cursor: 'pointer', color: Colors.BLACK}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span style={{marginRight: '.2em'}}>{scanLinks.length} scans</span> <Icon icon="caret-down" />
            </div>
          </Link>
        </Popover2>
      )
    } else {
      scanDisplay = (
        <a
          style={{color: Colors.BLACK}}
          href={scanLinks[0]}
          target="_blank"
        >
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span style={{marginRight: '.2em'}}>Scan</span> <Icon icon="arrow-top-right" size={14} />
          </div>
        </a>
      )
    }
  }
  return scanDisplay
}

// expects bibliography to be expanded on the bibref
export const BibRefCard = ({ bibref, style, ...props }) => {
  const { bibliography, notes } = bibref

  let name = bibliography.name
  if (bibliography.link) {
    name = (
      <a
        target="_blank"
        href={bibliography.link}
        style={{textDecoration: 'none', color: Colors.BLACK, fontWeight: 'bold'}}
      >
        {bibliography.name}
      </a>
    )
  }
  const cardStyle = {
    backgroundColor: 'rgb(250, 240, 216)',
    boxShadow: 'none',
    border: `1px solid ${Colors.BLACK}`,
    padding: '0',
    ...style,
  }

  const link = bibliography.is_catalogue ? paths.catalogues(bibliography.identifier) : paths.bibliographies(bibliography.identifier)
  return (
    <Card style={cardStyle} {...props}>
      <div 
        style={{
          textAlign: 'left',
          borderBottom: `1px solid ${Colors.BLACK}`,
          display: 'flex',
        }}
      >
        <Link
          to={link}
          className="hoverable"
          style={{textDecoration: 'none', color: Colors.BLACK, fontSize: '12px', padding: '6.6px 20px', borderRight: `1px solid ${Colors.BLACK}`}}
        >
          {bibliography.identifier}
        </Link>
      </div>
      <div style={{color: Colors.BLACK}}>
        <div style={{padding: '20px'}}>
          {name}
        </div>
        <div
          style={{
            overflowWrap: 'break-word',
            borderTop: `1px solid ${Colors.BLACK}`,
            padding: '10px 20px',
          }}
        >
          <MicroInfo
            none="No notes"
            info={<Notes notes={notes} linkOptions={{target: '_blank'}} />}
          />
        </div>
      </div>
    </Card>
  )
}

export const BibRefList = ({ bibrefIds, none="No bibliographic references" }) => {
  const [bibMap, setBibMap] = useState(null)

  useEffect(() => {
    // TODO, this query seems to be running 3 times on initial render in the exact same fashion.
    // some optimization could be done here.
    if (Array.isArray(bibrefIds) && bibrefIds.length > 0) {
      api.bibRef({ query: { 'id.in': bibrefIds, '_ex': 'bibliography' } })
        .then(data => {
          setBibMap(data.reduce((acc, curr) => {
            acc[curr.id] = curr
            return acc
          }, {}))
        })
    }
  }, [bibrefIds])

  if (!Array.isArray(bibrefIds) || bibrefIds.length === 0) {
    return <Noner none={none}>{null}</Noner>
  }

  if (!bibMap) {
    return 'Loading...'
  }

  const elems = bibrefIds.map(b => (
    <BibRefCard key={b} bibref={bibMap[b]} style={{marginBottom: '1em'}} />
  ))

  return (
    <div>{elems}</div>
  )
}

export const FolioInfo = ({ folios, frontFlyLeaves, backFlyLeaves, zeroFolioText='Unknown number of folios' }) => {
  const hasFolios = folios > 0
  const folioText = hasFolios ? folios : zeroFolioText
  let display = (
    <span>
      {frontFlyLeaves > 0 && <span>{frontFlyLeaves} + </span>} <span><Noner none={zeroFolioText}>{folios}</Noner></span> {backFlyLeaves > 0 && <span> + {backFlyLeaves}</span>}
    </span>
  )

  if (frontFlyLeaves || backFlyLeaves) {
    let helper = `${folioText}${hasFolios ? plur(folios, ' folio', ' folios') : ''}`
    if (frontFlyLeaves) {
      helper = `${frontFlyLeaves} fly ${plur(frontFlyLeaves, 'leaf', 'leaves')} + ${helper}`
    }
    if (backFlyLeaves) {
      helper = `${helper} + ${backFlyLeaves} fly ${plur(backFlyLeaves, 'leaf', 'leaves')}`
    }
    display = (
      <Tooltip2 className={popover.Classes.TOOLTIP2_INDICATOR} content={helper}>
        {display}
      </Tooltip2>
    )
  }

  return display
}

// if both width and height are 0, returns a Noner
export const Dimensions = ({ width, height, none="None", units="mm"}) => {
  if (width === 0 && height === 0) {
    return <Noner none={none}>{null}</Noner>
  }
  return (
    <>
      <span title="Width">{width}{units}</span> x <span title="Height">{height}{units}</span>
    </>
  )
}

export const CardTitleLink = ({ children, style, ...props }) => {
  return (
    <Link
      style={{
        minWidth: 0, /* to help text overflow ellipsis */
        color: Colors.BLACK,
        fontWeight: '600',
        textDecoration: 'none',
        fontSize: '15px',
        ...style,
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

export const WorkDate = ({ work }) => {
  if (!work) {
    return <Noner none="Unknown" />
  }
  return (
    <>
      <span>
        {dateUtils.formatDateModifier(work.date_modifier)}
      </span> <DateRange start={work.date_start} end={work.date_end} dateRange={work.date_range ? work.date_range.id : null} />
    </>
  )
}

export const Crumbline = ({ items, showCrumbs=true, showCitation=true, showAbout=true }) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      {showCrumbs ? <Crumbs items={items} /> : <span></span>}
      <div style={{display: 'flex', alignItems: 'center'}}>
        {showAbout &&
          <div style={{marginRight: '.5em'}}>
            <BoxTag link={paths.about()}>About</BoxTag>
          </div>
        }
        {showCitation &&
          <div style={{marginRight: '.5em'}}>
            <BoxTag link={paths.citation()}>Cite</BoxTag>
          </div>
        }
        <BoxTag href={urls.downloadCsv()}>Download</BoxTag>
      </div>
    </div>
  )
}

export const InfoTip = ({ children, content, contentStyle, containerStyle, style, popoverProps }) => {
  let display = 'i'
  if (children) {
    display = children
  }

  const cs = {
    padding: '.5em',
    ...contentStyle,
  }

  return (
    <div
      style={{...containerStyle}}
    >
      <Popover2
        popoverClassName={Classes.DARK}
        content={<div style={{...cs}}>{content}</div>}
        {...popoverProps}
      >
        <span
          className="hoverable"
          style={{
            cursor: 'pointer',
            border: `1px solid ${Colors.BLACK}`,
            color: Colors.BLACK,
            borderRadius: '2px',
            paddingLeft: '5px',
            paddingRight: '5px',
            ...style,
          }}
        >
          {display}
        </span>
      </Popover2>
    </div>
  )
}

export const Divider = () => (
  <div
    style={{
      marginLeft: '8px',
      marginRight: '8px',
      borderBottom: `1px solid ${Colors.BLACK}`,
      borderRight: `1px solid ${Colors.BLACK}`,
    }}
  >
  </div>
)
